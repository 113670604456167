<template>
  <div class="row mt--lg">
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Nome:</b> {{ capacity.name }}
      </getecma-p>
      <getecma-p color="theme-900-100" size="md">
        <b>Tipo:</b> {{ typeText }}
      </getecma-p>
      <getecma-p v-if="capacity.type === 0" color="theme-900-100" size="md">
        <b>Duração:</b> {{ capacity.duration }} minutos
      </getecma-p>
      <getecma-p color="theme-900-100" size="md">
        <b>Quilos (peso):</b> {{ formatKilos }}
      </getecma-p>
      <getecma-p color="theme-900-100" size="md">
        <b>Litros (volume):</b> {{ formatLiters }}
      </getecma-p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GetecmaCapacityViewInformation',
  props: {
    capacity: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formatLiters() {
      return this.formatNumber(this.capacity.liters);
    },
    formatKilos() {
      return this.formatNumber(this.capacity.kilos);
    },
    typeText() {
      return this.capacity.type === 0 ? 'Coleta' : 'Veículo';
    },
  },
  methods: {
    formatNumber(number) {
      return number.toString().replace('.', ',');
    },
  },
};
</script>
