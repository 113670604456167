import { get, put, post, remove } from '@/helpers/http';

export function getCapacitiesById(id) {
  return get(`/capacities/${id}`);
}

export function updateCapacityInformation(capacity) {
  return put(`/capacities/${capacity.id}`, capacity);
}

export function createNewCapacity(capacity) {
  return post('/capacities/', capacity);
}

export function removeCapacity(capacity) {
  return remove(`/capacities/${capacity.id}`);
}

export function fetchCapacities(pagination = {}) {
  return get('/capacities', { ...pagination });
}
