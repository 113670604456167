<template>
  <getecma-single-content-layout content-class="scroll pe--xxl">
    <template v-if="capacity" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl" />
      <getecma-row justify="center" class="d-flex align-items--center d-xs-flex-column">
        <getecma-col :xs="24" :span="17" justify="left">
          <getecma-capacity-view-information :capacity="capacity" />
        </getecma-col>
      </getecma-row>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError } from '@/services/toastService';
import { getCapacityById } from '@/modules/capacity/capacity.service';
import { MY_SPACE_URL } from '@/modules/my-space/my-space.constants';

import GetecmaCapacityViewInformation from '@/modules/capacity/components/CapacityViewComponent.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaCapacityViewPage',
  components: {
    GetecmaCapacityViewInformation,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Unidades de Medida', path: MY_SPACE_URL.capacities.path },
        { name: 'Visualizar', path: this.$route.path },
      ],
      capacity: null,
      pagination: {
        page: 1,
        limit: 5,
      },
    };
  },
  computed: {
    capacity_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getCapacityById(this.capacity_id)
        .then(data => {
          this.capacity = data;
        })
        .catch(() => toastError('Erro ao obter unidade de medida por ID'));
    },
  },
};
</script>
