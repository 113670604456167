import * as capacityResource from '@/modules/capacity/capacity.resource';

export function fetchCapacities(pagination = {}) {
  return new Promise((resolve, reject) =>
    capacityResource
      .fetchCapacities(pagination)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function getCapacityById(id) {
  return new Promise((resolve, reject) =>
    capacityResource
      .getCapacitiesById(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function createNewCapacity(capacity) {
  return new Promise((resolve, reject) =>
    capacityResource
      .createNewCapacity(capacity)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function updateCapacityInformation(capacity) {
  return new Promise((resolve, reject) =>
    capacityResource
      .updateCapacityInformation(capacity)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
export function removeCapacity(capacity) {
  return new Promise((resolve, reject) =>
    capacityResource
      .removeCapacity(capacity)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
